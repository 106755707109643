body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100%;
  background-color: var(--bodyBackground) !important;
  font-size: 16px; /* Default size */
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bodyBackground: #ffffff;
  --customSecondary: #ee259a;
  --lightYellow: #ffc000;
  --darkGrey: #5c636a;
  --lightOrange: #f4a342;
  --darkOrange: #c67e27;
  --customPrimary: #0C969C;
  --customPrimaryDark: #0C969C;
  --customLightBlue: #5294e2;
  --customDarkBlue: #2361ad;
}

.row {
  margin: 0 !important;
}

.content-container {
  position: relative;
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  min-height: 100%;
  background-color: #ffffff !important;
}

.container {
  margin: auto !important;
  padding: 0;
  min-width: 100% !important;
}

.page-heading {
  line-height: 1.0834933333 !important;
  letter-spacing: -0.003em !important;
  font-size: 19px !important;
}

.sub-heading {
  color: #202021;
  word-break: break-word;
  font-size: 15px;
  line-height: 20px;
}

.custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px !important;
  min-width: fit-content;
  color: #ffffff !important;
}

.custom-btn:hover,
.custom-btn:active,
.custom-btn:focus-visible {
  color: #ffffff !important;
}

.filter-button,
.reset-button,
.btn-primary:hover,
.btn-primary:active {
  background-color: var(--customPrimaryDark)!important;
  color: #ffffff !important;
}

.custom-btn,
.btn-secondary,
.btn-secondary:active {
  font-size: 16px;
  background-color: var(--customPrimaryDark);
  color: #ffffff !important;
  border: unset !important;
}

.btn-secondary:hover {
  background-color: var(--customPrimary);
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 13px !important;
  color: var(--darkGrey) !important;
  max-width: unset !important;
}

.css-169iwlq-MuiCalendarPicker-root {
  z-index: 3;
}

.css-9z7u3l-MuiPaper-root {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.filters_row input[type="text"] {
  height: unset !important;
  background: none !important;
  font-size: 13px !important;
}

.images {
  height: 20px;
  width: 20px;
}

.dropdown-menu label {
  margin-top: 0 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--darkGrey) !important;
}

.css-ncoufy-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0 10px 0 0 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-1ll44ll-MuiOutlinedInput-notchedOutline {
  width: 100% !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.date_selected .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.date_selected .css-1ll44ll-MuiOutlinedInput-notchedOutline {
  z-index: -10;
  color: #ffffff !important;
  border: 2px solid var(--darkGrey);
  background-color: var(--darkGrey);
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 6px !important;
}

.document-generation-buttons .MuiIconButton-root {
  color: #0C969C;
}

.document-generation-buttons button {
  font-size: 12px !important;
}

.css-16wblaj-MuiInputBase-input-MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input.css-1pk1fka {
  padding: 12px !important;
}

.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex;
  flex-direction: column;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: var(--customPrimaryDark) !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  width: 100% !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-19qnlrw-MuiFormLabel-root-MuiInputLabel-root,
.css-160rfsr {
  transform: translate(14px, 9px) scale(1) !important;
  font-size: 13px !important;
}

.MuiIconButton-root {
  padding: 2px !important;
  margin: 0 !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root,
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
.css-quhxjy-MuiInputBase-root-MuiOutlinedInput-root,
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-iz33ar {
  padding-right: 0 !important;
  background-color: transparent !important;
  height: 35px !important;
  color: var(--darkGrey);
  border-color: var(--darkGrey) !important;
  border-width: 3px !important;
}

[class*="MuiOutlinedInput-input"],
.MuiOutlinedInput-input.css-1pk1fka{
 font-size: 13px !important;
 color: #000000de;
}

.form-control[type="file"],
.form-select {
  height: 33px !important;
  border-width: 1px !important;
  border-color: var(--darkGrey) !important;
}

.dates_group {
  width: 275px;
  display: flex;
  gap: 0 35px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.css-1xp5r68-MuiFormControl-root-MuiTextField-root,
.MuiFormControl-root.MuiTextField-root.css-1pv2xcc {
  width: 100% !important;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px !important;
}

.rdt {
  width: 100% !important;
}

.table_searchbar {
  width: 250px !important;
  margin-left: auto;
}

.custom_DataTable .right_align {
  justify-content: flex-end !important;
}

.datagrid .odd {
  background-color: #ececec !important;
}

input[type="email"],
input[type="password"],
input[type="search"],
input[type="text"] {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}