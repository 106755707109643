.login-background {
   overflow-x: hidden;
   background: url('../images/login-background.jpeg') center/cover;
   position: relative;
   height: 100vh;
   width: 100vw;
   display: flex;
   align-items: center;
   border-top: 30px solid #0C969C;
}

input::-ms-reveal,
input::-ms-clear {
   display: none;
}

.login-background::before {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
}

.login {
   width: fit-content;
   padding: 20px;
   z-index: 1000;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: fit-content;
   background-color: #2C3832;
   width: 40%;
   margin-left: 10%;
   border-radius: 10px;
   border: 1px solid #ffffff;
}

.login-logo {
  height: 100px;
}

.login-container .alert {
   padding: 5px 15px;
}

.login-container h6 {
   background-color: #0C969C;
   width: 100%;
   padding: 8px;
   color: white;
   font-weight: lighter !important;
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   margin-bottom: 0;
}

.login-form.change-password {
   min-height: 270px;
}

.login-link {
   color: #0C969C !important;
   text-decoration: none !important;
   font-size: 12px;
}

.forgot-password {
   margin-top: -5px;
   font-weight: 400;
}

.login h1 {
   font-family: Montserrat, sans-serif;
   font-weight: bold;
   padding: 0 1em;
   text-align: center;
   font-size: 2em;
}

.login .alert {
   width: 60%;
   margin-bottom: 1em;
   padding: 0.8em 1em;
}

.login form {
   width: 60%;
   text-align: center;
   font-size: 13px;
}

.login #email,
.login #password,
.login #newPassword,
.login #currentPassword,
.login #confirmPassword {
   border-radius: 5px;
   padding: 0.3em 1em;
   font-size: 13px;
}

.login a {
   text-decoration: none;
}

.login img {
   width: 45%;
   margin-bottom: 1.5em;
}

.login-form.reset-password {
   min-height: 235px;
}

.password-container {
   display: flex;
   align-items:  center;
   width: 100%;
}

.show-hide-password {
   font-size: 23px;
   margin-left: -35px;
   cursor: pointer;
   color: #6c757d;
}

.login-form .sub-title {
   font-size: 14px;
   text-align: left;
}

.login-button {
   display: flex;
   align-content: center;
   justify-content: center;
   width: 100%;
   font-weight: lighter;
   margin-top: 1.5em;
}

@media only screen and (max-width: 768px) {
   .login form,
   .login .alert,
   .login .sub-title {
      width: 80%;
   }

   .login img {
      width: 28%;
   }
}

.add-user-form {
   width: 100%;
   display: flex;
   flex-direction: column;
   row-gap: 0.8em;
}
 
.add-user-form .form-group {
   display: flex;
   justify-content: space-between;
   align-items: center;
   column-gap: 2em;
}
 
.add-user-form .form-group label {
   width: 20%;
}

.login-container {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-width: 450px;
   width: 100%;
   padding: 0 0.5em;
}

.login-container > div {
   width: 100%;
   max-width: 450px;
   text-align: center;
}

.login-form {
   position: relative;
   width: 100%;
   min-height: 245px;
   padding: 2em;
   overflow: hidden;
   border: 1px solid #EEEFF3;
   border-radius: 8px !important;
   background-color: white;
}

.login-form .alert {
   margin-bottom: 0;
}

.login-form input {
   font-size: 13px;
}