.content-section {
  height: 100vh;
  width: 100%;
  margin-left: 200px;
  transition: all 0.3s ease-in-out;
}

.ps-collapsed .css-um1o6k {
  display: none;
}

.ps-collapsed .css-1l5fxv3 {
  margin-top: 1px !important;
}

.ps-collapsed .css-x3y57u {
  transform: translate3d(85px, 90px, 0px) !important;
}

.ps-sidebar-root.ps-collapsed + .content-section {
  margin-left: 80px !important;
}

.fpTHfu {
  background: transparent linear-gradient(180deg, #005583 0%, #7895b8 100%) 0%
    0% no-repeat padding-box;
}

.logo-section {
  margin: 5px auto 0;
  width: fit-content;
  padding: 0.5em;
  background: #ffffff;
  border-radius: 5px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.sub-menu-content,
.ps-sidebar-container,
.css-ewdv3l {
  background-color: var(--customPrimaryDark) !important;
  color: #ffffff;
}

.ps-sidebar-container{
  display: flex;
  flex-direction: column; /* Stack items vertically */
  height: 100vh; /* Full viewport height */
}

.menu-list-container {
  flex: 1; /* Take up remaining space between logo and logout button */
  overflow-y: auto; /* Add scrolling if the menu is long */
}

.css-o1cism > .ps-menu-button,
.css-o1cism > .ps-menu-button:hover,
.css-12vkui9 > .ps-menu-button:hover {
  background-color: #ffffff;
  color: var(--customPrimaryDark) !important;
}

.single-dropdown > .ps-menu-button {
  padding-left: 0 !important;
}

.logo-section .logo {
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.etCBxB {
  transform: none !important;
  -webkit-transform: none !important;
}

.menu svg {
  height: 40px;
  width: 40px;
}

.gnJkew {
  background: transparent !important;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.menu ul {
  gap: 5px 0;
}

.admin_dropdown .sub-menu-content ul {
  gap: unset !important;
}

.sidebar,
.css-1wvake5 {
  border: none !important;
  width: 200px !important;
  min-width: 200px !important;
  position: fixed !important;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.sidebar span {
  font-size: 12.5px;
}

.admin_dropdown .menu-anchor {
  background-color: transparent !important;
  display: flex;
  align-items: center;
}

.sub-menu-content .menu-anchor {
  height: 30px !important;
}

.menu-anchor:hover {
  background-color: var(--customPrimaryDark) !important;
  color: #ffffff !important;
}

.sidebar.ps-collapsed,
.css-1wvake5.ps-collapsed {
  width: 80px !important;
  min-width: 80px !important;
}

.ps-collapsed .css-wx7wi4 {
  width: 100% !important;
  min-width: 100% !important;
}

.ps-menu-button.ps-open,
.ps-active {
  font-weight: bold;
}

.ps-menu-icon {
  width: 20px !important;
  min-width: 20px !important;
  margin-right: 5px !important;
  margin-top: 1px !important;
  margin-left: 10px !important;
}

.logout-icon {
  width: 15px !important;
  min-width: 15px !important;
  margin-top: -1px !important;
}

.btn:active {
  border-color: transparent !important;
}

.ps-collapsed .ps-menu-label {
  padding: 0 10px !important;
}

.content-section.ps-collapsed {
  margin-left: 80px;
  transition: all 0.3s ease-in-out;
}

.admin_dropdown .sub-menu-content {
  background-color: transparent !important;
  position: relative !important;
  transform: unset !important;
}

.footer {
  bottom: 1em;
}

.ps-collapsed .admin_dropdown .menu-label {
  display: none !important;
}

.ps-collapsed .admin_dropdown .menu-anchor .menu-label {
  display: none !important;
}

.ps-collapsed .logo-section {
  display: flex;
  justify-content: center;
  padding: 0.5em !important;
}

.ps-collapsed .logo-section .logo {
  height: 13px;
}

.etCBxB {
  position: unset !important;
}

.active .menu-anchor {
  background-color: #ffffff !important;
  color: #0C969C !important;
}

.ps-menuitem-root > .ps-active,
.ps-menuitem-root > .ps-open:hover,
.css-eh8qic > .ps-menu-button:hover,
.css-16jkw2k > .ps-menu-button:hover,
.css-16jesut > .ps-menu-button:hover,
.css-1ffbfs > .ps-menu-button:hover {
  background-color: #ffffff !important;
  color: #0C969C !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background: #0C969C !important;
}

.css-16jesut > .ps-menu-button,
.css-1t8x7v1 > .ps-menu-button,
.css-16jkw2k > .ps-menu-button,
.css-eh8qic > .ps-menu-button,
.css-1t8x7v1 >.ps-menu-button  {
  padding-left: 0 !important;
}

.css-12vkui9 > .ps-menu-button,
.css-o1cism > .ps-menu-button {
  padding-left: 60px !important;
}

.ps-menuitem-root > .ps-menu-button {
  height: 32px !important;
  font-size: 12px !important;
}

.logout-button {
  color: white !important;
  border-color: white !important;
  width: -webkit-fill-available;
  margin: 10px 15px 0 !important;
  text-transform: none !important;
}

.logout-button span {
  margin-top: -2px;
}

.ps-collapsed .logout-button {
  flex-direction: column;
  margin: 15px 8px 0 !important;
  align-items: center !important;
}

.ps-collapsed .logout-button span {
  margin: auto auto 3px;
}

.ps-submenu-content .ps-menuitem-root > .ps-menu-button {
  padding-left: 35px !important;
}

.ps-collapsed .ps-submenu-content .ps-menuitem-root > .ps-menu-button {
  padding-left: 10px !important;
}

.top-header {
  height: 48px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: auto;
  right: 0;
  left: 200px;
  z-index: 50;
  transition: all 0.3s ease-in-out;
}

.top-header.collapsed {
  left: 80px;
  transition: all 0.3s ease-in-out;
}

.header-icon {
  font-size: 24px;
  padding-right: 5px;
  color: #ffffff;
}

.profile-container {
  padding: 8px 10px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background-color: #0C969C;
}

.user-details {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.user-details span,
.user-details small {
  font-weight: bold;
  display: block;
  color: white;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.user-details small {
  font-weight: lighter;
  font-size: 11.5px;
}

.action {
  padding-left: 0.5em;
}

.action .profile {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.action .profile img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.action .menu {
  position: absolute;
  top: 120px;
  right: 5px;
  padding: 0.2em 0.5em;
  background: var(--customPrimaryDark);
  width: fit-content;
  border-radius: 5px;
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
}

.menu-list {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.ps-collapsed .menu-list {
  height: calc(100vh - 250px);
}

a.ps-menu-button:hover {
  background-color: white !important;
}

.action .menu.active {
  top: 60px;
  visibility: visible;
  opacity: 1;
}

.action .menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 15px;
  width: 20px;
  height: 20px;
  background: var(--customPrimaryDark);
  transform: rotate(45deg);
}

.action .menu h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 10px 0;
  font-weight: 500;
  color: white;
  line-height: 1.5em;
}

.action .menu h3 span {
  font-size: 14px;
  color: white;
  font-weight: 300;
}

.action .menu ul {
  padding: 0 !important;
  margin: 0;
}

.action .menu ul li {
  list-style: none;
  display: flex;
  align-items: center;
}

.action .menu ul li:last-child {
  padding-bottom: 0;
}

.action .menu ul li .icon {
  width: 18px;
  color: white;
  margin-right: 5px;
  transform: scaleX(-1);
}

.action .menu ul li a {
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: 0.5s;
}

.action .menu ul li:hover a {
  color: var(--customLightBlue);
}

@media (max-width: 992px) {
  .logo-section .logo {
    height: 25px;
  }

  .content-section {
    margin-left: 0;
  }

  .top-header {
    width: 100%;
    left: auto;
  }
}

@media (max-width: 320px) {
  .ktVuSn {
    font-size: small;
  }
}

.fpTHfu {
  background-color: transparent !important;
}

.loader {
  flex-direction: column;
  align-items: center;
}

.loader-message {
  margin-top: 1em;
}

.ps-collapsed .ps-menu-button:not(.ps-submenu-content *) {
  flex-direction: column;
  height: auto !important;
  margin: 1em auto;
  padding-right: 0 !important;
  padding-top: 5px;
  padding-bottom: 3px;
}

.ps-collapsed .ps-menu-icon:not(.ps-submenu-content *) {
  margin: 0 !important;
  font-size: 14px;
  height: fit-content;
}

.ps-collapsed .ps-menu-label:not(.ps-submenu-content *) {
  font-size: 12px;
  margin-top: 5px;
  text-wrap: auto;
  text-align: center;
  line-height: normal;
}