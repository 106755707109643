#invalid {
    position: relative;
    height: 100vh;
}

#invalid .invalid {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.invalid {
    max-width: 410px;
    width: 100%;
    text-align: center;
}

.invalid .invalid-code {
    height: 280px;
    position: relative;
    z-index: -1;
}

.invalid .invalid-code h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 230px;
    margin: 0px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(90deg, rgba(11,113,61,1) 10%, rgba(108,181,69,1) 50%, rgba(100,193,148,1) 90%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.invalid h2 {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
}

.invalid p {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0px;
}

.invalid a {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    text-decoration: underline;
}

.invalid a:hover {
    color: black;
}
  
@media only screen and (max-width: 768px) {
    .invalid .invalid-code {
        height: 142px;
    }

    .invalid .invalid-code h1 {
        font-size: 112px;
    }
}  